@media (max-width: 1400px) {
    .home-hero-section {
        .hero-content-section {        
            .hero-content-left {
                .hero-content-tagline {                
                    h6 {
                        
                    }                
                }
                .hero-content-title {                
                    h1 {
                        font-size: 48px;                    
                    }
                }
            }
            
        }
    }
    .proces-shapes-image {
        &.flow-image-3 {
            img {
                width: 200px;
            }
        }
    }
    .group-client-block {
        .group-client-navtabs {
            li {
                width: 31%;
                margin-right: 20px;
                a {
                    .group-pic {
                        img {
                            max-height: initial;
                            object-fit: cover;
                            width: 100%;      
                            border-radius: 20px;                      
                        }
                        .group-overlay {
                            h6 {
                                font-size: 25px;
                                line-height: 30px;
                                top:-48px;
                                padding: 0 20px;
                            }
                        }
                    }
                }
            }            
        }
        
        .group-tab-client-content {
            #profile-tab-pane .group-content-box:before {
                left: 350px;
            }
            #contact-tab-pane .group-content-box:before {
                left: 650px;
            }
        }
                
    } 
}
@media (max-width: 1200px) {
    .shape-circle-wrapper {
        .shape-image {                        
            &.shape-image-5 {
                bottom: -83%;
                left: 29%;                
            }
        }
    }
}
@media (max-width: 1024px) {
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }
    .testimonial-section  {     
        .testimonial-vector {
            img {
                width: 350px;
            }
        }
    }
    .plan-bg-hero-section {     
        height: 500px;
        &.client-hero-section {
            height: 400px;
            background-size: contain;
            background-position: center;
            .plan-hero-content-left {
                .section-heading-content {
                    padding: 50px 50px 0 50px;
                    margin-top: 0px;
                }
            }   
        }   
        .group-client-vector {        
            img {
                width: 100px;
            }        
        }
    }  
            
}
@media (max-width: 991px) {
    .nav-mobile-no {
        font-size: 16px;
        color: #183442;
        font-weight: 700;
    }
    .home-hero-section {
        height: 650px;
        background-position: right center;
        .hero-content-section {   
            padding: 5px 0;     
            .hero-content-left {
                .hero-content-tagline {   
                    margin-bottom: 10px;    
                    h6 {
                        font-size: 16px;
                        line-height: 30px;
                    }                             
                }
                .hero-content-title {                
                    margin-bottom: 10px;                                 
                    h1 {
                        font-size: 32px;
                        line-height: 39px;                    
                    }
                }
            }
            .hero-content-right {
                .chat-avtarbox {
                    display: flex;
                    position: absolute;
                    bottom: -20px;
                    left:0px;
                    right: 0px;
                    justify-content: center;
                    .chat-avtarbox-content {                       
                        width: 32%;
                        margin-right: 6px;
                        .avtarbox-body {
                            .chat-bubblebox {
                                .bubble-round-chat {
                                    position: relative;
                                    font-size: 12px;
                                    line-height: 14px;
                                    width: 250px;                                    
                                    margin: 0 -18px 15px 0;
                                    &:before {
                                        content: "";
                                        width: 0;
                                        height: 0;
                                        position: absolute;
                                        border-left: 24px solid $white-color;
                                        border-right: 12px solid transparent;
                                        border-top: 12px solid $white-color;
                                        border-bottom: 20px solid transparent;   
                                        bottom: -18px;                                
                                    }
                                }
                            }
                            .avtar-thumbnail {
                                img {
                                    height: 250px;
                                }
                            }
                        }
                        &.chat-avtarbox-content-one {
                            .avtarbox-body {
                                .chat-bubblebox {
                                    .bubble-round-chat {
                                        &:before {
                                            content: "";                                       
                                            left: 43px;                                        
                                        }
                                    }
                                }
                            }           
                        }
                        &.chat-avtarbox-content-two {
                            .avtarbox-body {
                                .chat-bubblebox {
                                    .bubble-round-chat {
                                        &:before {
                                            content: "";                                       
                                            right: 105px;                                        
                                            border-left: 12px solid transparent;
                                            border-top: 12px solid $white-color;
                                            border-bottom: 24px solid transparent;
                                            border-right: 30px solid $white-color;
                                        }
                                    }
                                }
                            }           
                        }
                    }
                }
            }
        }
        &:before {
            width: 100%;
            height: 100%;
            background: linear-gradient(89.39deg, #00000099 0.53%, #00000000 99.47%);
            backdrop-filter: blur(7.5px);
        }
    }
    .header-section {
        padding: 15px 0px;        
        .header-block {
            .container {
                padding: 0;
            }
            .navbar {
                padding: 0px;
                .navbar-brand {
                    margin-right: 0px;
                    margin-left: 15px;
                    img {
                        width: 113px;
                        height: 58px;
                    }
                }
                .navbar-toggler {
                    padding: 8px 9px;
                    border-radius: 100%;
                    background: #007D94;
                    color: #fff;
                }
                .navbar-collapse {
                    position: absolute;
                    top: 70px;
                    background: $white-color;
                    box-shadow: 0 0 25px #00000026;
                    width: 100%;
                    padding: 15px;
                    z-index: 111;
                    .navbar-nav {
                        li {
                            margin-right: 20px;
                            &:last-child {
                                margin-right: 0px;
                            }
                            a {
                                padding-left: 0px;    
                                padding-right:0px ;
                                font-size: 18px;
                                color: $heading-color;
                                font-weight: 700;
                                .nav-item {
        
                                }
                            }
                        }
                    }
                    .navbar-right-sec {
                        margin-left: 0;
                        display: block;
                        width: 100%;
                        margin-top: 10px;
                        a {
                            margin-bottom: 10px;
                            display: block;
                            &.btn-search {
                                margin-right: 0px;
                            }
                        }
                        
                    }
                }
            }
        }
    }
    .btn {
        &.btn-small-green-bg,&.btn-small-no-bg  {
            padding: 11px 25px;
        }
    }
    .signmeup-section {
        .signup-wrapper {
            padding: 15px 25px 0 25px;
            .signup-wrapper-order-1 {
               
            }
            .signup-wrapper-order-2 {
                .signme-up-content-right {
                    p {
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
    .services-section {
        margin-bottom: 20px;
        &::before {
            transform-origin: -50% 0;
        }
        &.corevalue-section {
            .service-title-block {
                .section-heading-content {
                    .section-desc {
                        p {
                            font-size: 16px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }
    .mission-section {
        padding: 70px 0;
        .targated-block {
            .target-left {
                padding: 25px;
            }
            .target-right { 
                img {                    
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                }
            }
        }
    }
    .triangle-shape {
        &.triangle-left,&.triangle-right {
            display: none;
        }

    }
    .section-heading-content {    
        .section-tagline {
            h6 {            
                font-size: 16px;
                line-height: 30px;            
            }
        }
        .section-title {        
            h3 {            
                font-size: 32px;
                line-height: 39px;            
            }
        }
        .section-title-large {
            h2 {                
                font-size: 32px;
                line-height: 39px;                
            }
        }
        .section-desc {
            p {                
                font-size: 16px;
                line-height: 24px;                               
            }
        }
    }
    .testimonial-section  {
        padding: 70px 0 0;
        .testimonial-vector {
            position: initial;
        }
    }
    .plan-hero-content-section {
        .plan-hero-content-left {
            .section-heading-content {
                .section-title-large {
                    margin-bottom: 70px;
                }
            }
        }
        &.client-hero-section {
            .plan-hero-content-left {
                .section-heading-content {
                    .section-title-large {
                        margin-bottom: 0px;
                    }
                }
            }   
        }
    }
    .team-section {
        padding: 50px 0;
        .owl-carousel  {
            .testimonial-slide {
                .team-member-pic {
                    img {
                        margin: 0 auto;
                    }
                }
                .member-name {
                    h6 {
                        text-align: center;
                    }
                }
            }
            .owl-nav {
                position: absolute;
                top: 19%;
                width: 100%;
                button {
                    background: #FFFFFF;
                    box-shadow: 0px 0px 25px #00000026 !important;
                    border-radius: 20px !important;
                    width: 24px;
                    height: 24px;
                    &.owl-prev {
                        position: absolute;
                        left: -3%;
                    }
                    &.owl-next {
                        position: absolute;
                        right: -3%;
                    }
                    &.owl-prev span,&.owl-next span {
                        font-size: 33px;
                        color: $heading-color;
                    }
                }
            }
        }
    }
    .client-objective-section {      
        .client-bullet-points {
            .client-bullet-list {
                li {
                    font-size: 16px;
                    line-height: 30px;
                    text-indent: -15px;
                    padding-left: 30px;                                        
                }
            }
        }        
    }
    .shape-circle-wrapper {
        .shape-image {
            &.shape-image-1 {
                top: -19px;
                left: 63%;
            }
            &.shape-image-2,&.shape-image-3,&.shape-image-4 {
                display: none;
            }
            &.shape-image-5 { 
                bottom: initial;
                left: 0;
                transform: none;
                top: -338px;
            }
        }
    }
    .plan-bg-hero-section {
        height: 550px;
    }  
    .home-hero-section {
        height: 600px;
        background-position: right center;
        .hero-content-section {   
            padding: 40px 0;     
        }    
    }      
    .signmeup-section {
        margin-bottom: 50px;
        &.gist-section {
            .signup-wrapper {
                padding: 15px;
                .signup-wrapper-box {
                    display: block;
                    justify-content: space-between;
                    border-bottom: 4px solid #E2E2E2;
                    margin-bottom: 20px;
                    .signme-up-content-left {
                        border-right: 0;    
                        padding-right: 0;
                        display: flex;
                        justify-content: space-between;
                        .section-heading-content {
                            .section-title {
                                h3 {
                                    font-size: 24px;
                                    line-height: 28px;
                                }
                            }
                        }
                    }
                    .gist-vector {
                        position: initial;
                        img {
                            width: 110px;
                        }
                    }                    
                }
                .signme-up-content-right {
                    border-left: 0px;
                    padding-left: 0px;
                    p {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
    }  
    .group-client-block {
        .group-tab-client-content {
            &.tab-content {
                >.tab-pane {
                    display: block;
                    opacity: 1;
                    .accordion-header {
                        .accordion-button {
                            background: none;
                            padding: 0;
                            margin-bottom: 30px;
                            border-radius: 0;
                            background-color: initial;
                            border: 0;
                            box-shadow: none;
                            display: table;
                            &::after {
                                content: none;
                            }
                            .group-client-block {
                                .group-pic {
                                    img {
                                        width: 100%;
                                        object-fit: cover;
                                        max-height: initial;
                                    }
                                }
                            }                           
                        }
                    }
                }   
            }
            .group-content-box {
                margin: 0 0 30px 0;
            }
            #profile-tab-pane .group-content-box:before,#contact-tab-pane .group-content-box:before {
                left: 70px;
            }
            
        }   
    }
    
}

@media (max-width: 767px) {
    .home-hero-section {        
        .hero-content-section {                  
            .hero-content-left {
                .hero-content-tagline {   
                    margin-bottom: 10px;    
                    h6 {
                        font-size: 16px;
                        line-height: 30px;
                    }                             
                }
                .hero-content-title {                
                    margin-bottom: 10px;                                 
                    h1 {
                        font-size: 32px;
                        line-height: 39px;                    
                    }
                }
            }
            .hero-content-right {
                .chat-avtarbox {                                     
                    left: initial;
                    right: 0px;
                    justify-content: center;
                    .chat-avtarbox-content {                       
                        width: 45%;                        
                        .avtarbox-body {
                            .chat-bubblebox {
                                .bubble-round-chat {                                  
                                    width: 100%;                                    
                                    margin: 0 -18px 20px auto;
                                    &:before {
                                        content: "";
                                        width: 0;
                                        height: 0;
                                        position: absolute;
                                        border-left: 24px solid $white-color;
                                        border-right: 12px solid transparent;
                                        border-top: 12px solid $white-color;
                                        border-bottom: 20px solid transparent;   
                                        bottom: -18px;                                
                                    }
                                }
                            }
                            .avtar-thumbnail {
                                img {
                                    height: 250px;
                                }
                            }
                        }                        
                        &.chat-avtarbox-content-two {
                            .avtarbox-body {
                                .chat-bubblebox {
                                    .bubble-round-chat {
                                        &:before {                                            
                                            right: 78px;                                                                                   
                                        }
                                    }
                                }
                            }           
                        }
                    }
                }
            }
        }
        &:before {
            width: 100%;
            height: 100%;
            background: linear-gradient(89.39deg, #00000099 0.53%, #00000000 99.47%);
            backdrop-filter: blur(7.5px);
        }
    }
    .mission-section {        
        .targated-block {           
            .target-right { 
                img {
                    height: 100%;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 20px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 20px;
                }
            }
        }
    }
    .signmeup-section {
        .signup-wrapper {
            padding: 15px 25px 0 25px;
            .signup-wrapper-order-1 {
                order: 2;
                text-align: center;
            }
            .signup-wrapper-order-2 {
                order: 1;
                .signme-up-content-left {
                    border-right: 0;
                    padding-right: 0;
                }
                .signme-up-content-right {
                    padding-left: 0px;
                }
            }
        }
    }
    .footer-section {
        .pattern-soft {
            top: -76px;
            padding-bottom: 6em;
            background-size: cover;
        }
        .footer-middle-conent {
            display: table;
            margin: 0 auto;
            .footer-link {
                .footer-links-list {
                    li {
                        margin-bottom: 10px;
                        a {
                            font-size: 14px;
                        }
                    }  
                }
            }
        }
        .footer-left-content {
            text-align: center;
            .footer-desc {
                p,span {
                    font-size: 14px;
                    line-height: 18px;                    
                }
                span {
                    margin-top: 40px;
                    display: block;
                }
            }
        }
        .footer-right-conent {
            display: table;
            margin: 0 auto;            
            margin-top: 15px;                            
            .footer-social-links {
                .footer-social-list {
                    margin-bottom: 0px;
                    li {
                        a {
                            i {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .client-objective-section {
        .client-objective-title-block {
            margin-bottom: 30px;
        }
        .client-bullet-points {
            .client-bullet-list {
                li {
                    font-size: 16px;
                    line-height: 30px;
                    text-indent: -15px;
                    padding-left: 30px;
                    width: 100%;
                    float: none;
                    margin-bottom: 5px;
                }
            }
        }
        .objective-vector {
            position: initial;
            margin-bottom: -50px;
            text-align: center;
            img {
                opacity: 1;
            }
        }
    }
    .plan-bg-hero-section {        
        &.client-hero-section {
            background-image: url(../../assets/images/client-hero-mobile.png) !important;
            background-size: cover;
            background-position: top center;
            height: 660px;
        }
    }
    .news-latter-section {
        padding-bottom: 150px;
        padding-top: 50px;
    }
    .group-client-block {
        .group-client-navtabs {
            display: none;
            &.group-client-single {
                display: block;
                li {
                    width: 100%;
                    margin-bottom: 15px;
                }
            }
        }
        .group-tab-client-content {            
            .group-mobile-tab {
                display: block;
                margin-bottom: 30px;
                .group-pic {
                    img {
                        max-height: initial;
                        object-fit: cover;
                        width: 100%;      
                        border-radius: 20px;                      
                    }
                    .group-overlay {
                        position: relative;
                        h6 {
                            position: absolute;
                            top: -48px;
                            font-weight: 700;
                            font-size: 25px;
                            line-height: 30px;
                            z-index: 1;
                            color: #fff;
                            padding: 0 20px;
                        }
                    }              
                }
            }           
        }
    }    
    .testimonial-section {
        .testimonial-circle-wrapper {
            display: block;
            .testimonial-shape-image {                        
                &.testimonial-image-1 {
                    bottom: 0;
                    top: 60%;
                    left: 4%;
                }
                &.testimonial-image-2 {
                    bottom: 0;
                    top: 60%;
                    left: 4%;
                }
                &.testimonial-image-3 {
                    left: 80%;
                    bottom: 42%;
                }
                &.testimonial-image-4 {
                    left: 10%;
                    top: 18%;
                }
                &.testimonial-image-5 {
                    top: 21%;
                    right: 9%;
                }            
        
            }
        }
    }
    .float-mobile-wapp {
        position:fixed;
        width:60px;
        height:60px;
        bottom:20px;
        right:20px;
        background-color:#25d366;
        color:#FFF;
        border-radius:50px;
        text-align:center;
        font-size:30px;
        box-shadow: 2px 2px 3px #999;
        z-index:100;
        display: flex;
        align-items: center;
        justify-content: center;        
    }
   
}
@media (max-width: 667px) {
    .plan-bg-hero-section {
        &.client-hero-section {
            height: 400px;
            background-position: center;
        }
    }

}
@media (max-width: 375px) {


}
@media (max-width: 480px) {


}
