@font-face {
	font-family: 'Calibri';
	src: url('../css/webfonts/calibri-regular.eot') format('embedded-opentype'),
	 url('../css/webfonts/calibri-regular.woff') format('woff'), 
	 url('../css/webfonts/calibri-regular.ttf') format('truetype'), 
	 url('../css/webfonts/calibri-regular.otf') format('otf');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
  
  }
  
  @font-face {
	font-family: 'Calibri';
	src: url('../css/webfonts/calibri-bold.eot') format('embedded-opentype'),
	 url('../css/webfonts/calibri-bold.woff') format('woff'), 
	 url('../css/webfonts/calibri-bold.ttf') format('truetype'), 
	 url('../css/webfonts/calibri-bold.otf') format('otf');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
  }
  @font-face {
	font-family: 'Calibri';
	src: url('../css/webfonts/calibri-italic.eot') format('embedded-opentype'),
	 url('../css/webfonts/calibri-italic.woff') format('woff'), 
	 url('../css/webfonts/calibri-italic.ttf') format('truetype'), 
	 url('../css/webfonts/calibri-italic.otf') format('otf');
	font-weight: 400;
	font-style: italic;
	font-display: swap;
  }
  @font-face {
	font-family: 'Calibri';
	src: url('../css/webfonts/calibri-bi.eot') format('embedded-opentype'),
	 url('../css/webfonts/calibri-bi.woff') format('woff'), 
	 url('../css/webfonts/calibri-bi.ttf') format('truetype'), 
	 url('../css/webfonts/calibri-bi.otf') format('otf');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
  }