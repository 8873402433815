@import "mixins";
@import "variables";


html {
    font-size: 62.5%;
    /* Sets up the Base 10 stuff */
    height: 100%;
}

body {
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-family: 'Calibri', sans-serif;
    font-weight: 400;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    height: 100%;
    -ms-font-feature-settings: normal;
    color: $paragraph-color;
    background-color: $grey-color;
}

a {
    transition: color .1s ease-in-out;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

input {
    font-size: 13px;
}

a:focus {
    outline: 0
}

b,
strong {
    font-weight: 500
}

label {
    color: $paragraph-color;
}

.semibold {
    font-weight: 500 !important;
}

.m-t-50 {
    margin-top: 50px;
}

/*Common color classes CSS Start*/
.theme-green-color {
    color: $primary-green-color;
}
.theme-blue-color {
    color: $heading-color;
}
.theme-green-background {
    background-color: $primary-green-color;
}
.theme-blue-background {
    background-color: $heading-color;
}

/*Common color classes CSS End*/

/*Common CSS Start*/
.section-heading-content {    
    .section-tagline {
        h6 {
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.2px;
            text-transform: uppercase;
            margin-bottom: 0;
        }
    }
    .section-title {        
        h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 44px;
            color: #183442;
        }
    }
    .section-title-large {
        h2 {
            font-weight: 700;
            font-size: 58px;
            line-height: 70px;
            color: $heading-color;
        }
    }
    .section-desc {
        p {
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: $heading-color;
            margin-bottom: 0px;
        }
    }
}
.card-box-bg {
    background: $white-color;
    box-shadow: 0px 0px 25px #00000026;
    border-radius: 20px;
}

.plan-bg-hero-section {
    background-color: #007d940d;
    width: 100%;
    height: 660px;
    .plan-hero-content-section {
        padding: 30px 0 20px 0;
        position: relative;
    }
}
.triangle-shape {
    position: absolute;
    z-index: -1;
    &.triangle-left {
        left: 0;
    }
    &.triangle-right {
        right: 0;
    }
}

.float-mobile-wapp {
    display: none;
}
/*Common CSS End*/


/*row grid css start--------------------------------------------*/

.row.row-grid>[class*="col-"]+[class*="col-"] {
    margin-top: 20px;
}

@media (min-width: 1199px) {
    .row.row-grid>[class*="col-xl-"]+[class*="col-xl-"] {
        margin-top: 0;
    }
}

@media (min-width: 992px) {
    .row.row-grid>[class*="col-lg-"]+[class*="col-lg-"] {
        margin-top: 0;
    }
}

@media (min-width: 768px) {
    .row.row-grid>[class*="col-md-"]+[class*="col-md-"] {
        margin-top: 0;
    }
}

@media (min-width: 576px) {
    .row.row-grid>[class*="col-sm-"]+[class*="col-sm-"] {
        margin-top: 0;
    }
}





