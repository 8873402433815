
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    color: $heading-color;
}

.h1,
h1 {
    font-size: 36px
}

.h2,
h2 {
    font-size: 30px
}

.h3,
h3 {
    font-size: 24px
}

.h4,
h4 {
    font-size: 18px
}

.h5,
h5 {
    font-size: 14px
}

.h6,
h6 {
    font-size: 12px
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    font-size: 60%;
    font-weight: 400;
    color: $heading-color;
}




