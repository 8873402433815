.header-section {
    padding: 15px 0px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: position 10s;
    -ms-transition: position 10s;
    -o-transition: position 10s;
    transition: all 0.5s ease;
    background-color: #f2f8fa;
    &.header-bg {
        box-shadow: 0px 4px 10px #0000001a;
        background: #F2F8FA;
    }
    .header-block {
        .navbar {
            padding: 0px;
            .navbar-brand {
                margin-right: 0px;
                img {
                    width: 113px;
                    height: 58px;
                }
            }
            .navbar-collapse {
                .navbar-nav {
                    li {
                        margin-right: 20px;
                        &:last-child {
                            margin-right: 0px;
                        }
                        a {
                            padding-left: 0px;    
                            padding-right:0px ;
                            font-size: 18px;
                            color: $heading-color;
                            font-weight: 700;
                            .nav-item {
    
                            }
                            &:hover {
                                color:$primary-green-color;
                            }
                            &.active {
                                color:$primary-green-color;
                            }
                        }
                    }
                }
                .navbar-right-sec {
                    margin-left: 50px;
                    a {
                        font-size: 18px;
                        color: $heading-color;
                        font-weight: 700;
                        &.btn-search {
                            margin-right: 20px;
                        }                        
                        &:hover {
                            color:$primary-green-color;
                        }
                    }
                    .search-form-block {
                        margin-right: 20px;
                        display: none;
                        .input-group {
                            display: flex;
                            align-items: center;
                            position: relative;
                            .input-group-addon {
                                position: absolute;
                                right: 7px;
                                z-index: 11;
                                .fa-search  {
                                    font-size: 18px;
                                    color: #183442;
                                    font-weight: 700;
                                }
                                
                            }
                            .form-control {
                                width: 100%;
                                height: 32px;
                                border: 1px solid #183442;                                
                                border-radius: 0;
                                font-size: 16px;
                                z-index: 1;
                                padding: 6px 12px;
                                &:focus,&:hover {
                                    outline: 0;
                                    box-shadow: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.fixed-header {
    .header-section  {
        animation: smoothScroll 1s forwards;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #f2f8fa;
        z-index: 111;
        @keyframes smoothScroll {
            0% {
                transform: translateY(-40px);
            }
            100% {
                transform: translateY(0px);
            }
        }                                
    }
}