.client-hero-section {
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    .plan-hero-content-section {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .group-client-vector {        
        position: relative;
        img {
            position: absolute;            
            left: 7%;
        }
    }
}
.target-section {
    padding: 100px 0px 50px 0px;
    background-color: $white-color;
    .group-client-title-block {
        margin-bottom: 50px;
    }
}
.group-client-block {
    .group-client-navtabs,&.group-client-single {
        border: 0;
        margin-bottom: 30px;
        text-align: center;
        li {
            width: 33%;
            float: left;
            a {
                border: 0;
                margin: 0;
                padding: 0;
               
                &.active {
                    border: 0;                    
                    .group-pic {
                        img {
                            filter: drop-shadow(0px 0px 30px #00000033);
                        }        
                    }    
                }
            }
        }
    }
    .group-pic {
        img {
            width: auto;
            object-fit: contain;
            height: auto;
            max-height: 300px;
            border-radius: 20px;
        }   
        .group-overlay {
            position: relative;
            h6 {
                position: absolute;
                top: -55px;
                font-weight: 700;
                font-size: 30px;
                line-height: 33px;
                z-index: 1;
                color: #fff;
                padding: 0 50px;
            }
        }              
    }
    .group-tab-client-content {
        .accordion-item {
            border:0;
        }
        .accordion-body {
            padding: 0;
            background: none;
        }
        .group-content-box {
            background: #E5F2F4;            
            box-shadow: 4px 4px 15px #00000033;
            padding: 30px;
            position: relative;
            z-index: 1;
            margin: 0 20px;
            border-radius: 10px;
            &:before {
                width: 0;
                height: 0;
                border-left: 30px solid transparent;
                border-right: 30px solid transparent;
                border-bottom: 50px solid #E5F2F4;
                content: '';
                z-index: 1;
                position: absolute;
                top: -30px;
                left: 70px;
            }
            h5 {
                font-weight: 700;
                font-size: 30px;
                line-height: 33px;
                margin-bottom: 10px;
                color: $heading-color;
            }
            p {
                font-weight: 400;
                font-size: 20px;
                line-height: 25px;
                letter-spacing: 0.5px;
                color: $heading-color;
                margin-bottom: 0;
            }
        }
        .group-mobile-tab {
            display: none;
        }
        #profile-tab-pane .group-content-box:before {
            left: 500px;
        }
        #contact-tab-pane .group-content-box:before {left: 940px;}
    }
}