/*1. Team Section CSS Start*/
.team-section {
    padding: 100px 0px;
    background-color: $white-color;
    .team-title-block {
        margin-bottom: 40px;
    }
    .team-block {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        .team-details {
            .team-member-pic {
                margin-bottom: 20px;
                img {
                    width: 180px;
                    height: 180px;
                    border-radius: 100%;
                    margin: 0 auto;
                }
            }
            .member-name {
                h6 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 120%;
                    color: $black-color;
                }
            }
            .member-desc {
                .post-name,.member-desc-list li {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    color: #00000080;
                    margin-bottom: 5px;
                }
            }
            .member-desc-list {
                padding-left: 30px;
            }
        }
    }   
}
/*1. Team Section CSS End*/

.shape-circle-wrapper {
    .shape-image {
        position: absolute;
        z-index: -1;
        &.shape-image-1 {
            top: 0;
            left: 24%;
        }
        &.shape-image-2 {
            left: -8%;
            top: 0;
        }
        &.shape-image-3 {
            left: 17%;
            bottom: 3%;
        }
        &.shape-image-4 {
            left: 48%;
            bottom: 26%;
        }
        &.shape-image-5 {
            bottom: -55%;
            left: 29%;
            transform: rotate(270deg);
        }
    }
}

.client-objective-section {
    background-color: #ACD1D8;
    padding: 100px 0;
    position: relative;
    z-index: 1;
    .client-objective-title-block {
        margin-bottom: 50px;
    }
    .client-bullet-points {
        .client-bullet-list {
            display: table;
            li {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.2px;
                color: $heading-color;
                width: 49%;
                text-indent: -15px;
                padding-left: 40px;
                float: left;
                margin-bottom: 10px;
                i {
                    margin-right: 10px;
                }
            }
        }
    }
    .objective-vector {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
        img {
            width: 120px;
            opacity: 0.5;
        }
    }    
}