.modal-header {
    color: $primary;
    font-weight: 500;    
    align-items: center;
    padding: 13px 15px;

    button {
        opacity: 1;        
        font-size: 18px;
    }

    .modal-title {
        font-weight: 400;
        font-size: 18px;
    }
}

.modal-body {
    padding: 2rem 1rem;
}

.modal-footer {
    justify-content: flex-start;
}