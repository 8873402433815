button {
    outline: none;
    font-weight: normal;
    font-size: 14px;
}

.btn:hover,
.btn:focus,
.btn:active {
    outline: none !important;
    // background: none;
    box-shadow: none !important;
}

button:hover,
button:focus,
button:active {
    outline: none !important;
    // background: none;
    box-shadow: none !important;
}

.btn {
    font-size: $font-size - 1;
    padding: 9px 12px;
    font-weight: 700;
    box-shadow: 0 0 9px 0 rgba(0,0,0,.1);
    border-radius: 5px;
    &.btn-small-green-bg {
        background-color: $primary-green-color;
        border-color: $primary-green-color;
        padding: 15px 37px;        
        color: $white-color;
        &:hover {
            background-color: $white-color;
            border-color: $primary-green-color;
            color: $heading-color;
        }
    }
    &.btn-small-blue-bg {
        background-color: $heading-color;
        border-color: $heading-color;
        padding: 15px 37px;        
        color: $white-color;
        &:hover {
            background-color: $white-color;
            border-color: $heading-color;
            color: $heading-color;
        }
    }
    &.btn-small-no-bg {
        background-color: transparent;
        border-color: $white-color;
        padding: 15px 37px;        
        color: $white-color;
        &:hover {
            background-color: $primary-green-color;
            border-color: $primary-green-color;
            color: $white-color;
        }
    }
}

.btn+.btn {
    margin-left: 10px !important;
}
