.custom-form-section {
    label {
        &.form-label {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: $primary-green-color;
            margin-bottom: 10px;
        }
    }
    .form-control {
        &.custom-text-input {
            height: 35px;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-bottom: 1px solid $grey-light-border;
            border-radius: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.28px;
            color: $small-txt-color;
            padding: 0;
        }
        &:hover,&:focus {
            outline: 0;
            box-shadow: none;
        }
    }
}