/*1. Footer Section CSS Start*/
.footer-section {
    // border-top-left-radius: 100% 50%;
    // border-top-right-radius: 100% 63%;
    background-color: $primary-green-color;
    padding: 0px 0 50px 0;
    position: relative;
    z-index: 1;
    .pattern-soft {
        position: absolute;
        top: -154px;
        background-image: url(../images/waves-footer-bg.png);
        background-repeat: no-repeat;
        padding-bottom: 12em;
        width: 100%;
        background-size: cover;
        z-index: 111;
    }    
    .footer-left-content {
        .footer-logo {
            margin-bottom: 20px;
        }
        .footer-desc {
            p {
                font-weight: 400;
                font-size: 18px;
                line-height: 170%;
                color: $white-color;
            }
            span {
                font-weight: 400;
                font-size: 15px;
                line-height: 170%;                
                letter-spacing: 0.02em;
                text-transform: capitalize;
                color: $white-color;
            }
        }
    }
    .footer-middle-conent {
        display: table;
        margin: 0 auto;
        .footer-heading-title {
            margin-bottom: 20px;
            h5 {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                color: $white-color;
                margin-bottom: 0px;
            }
        }
        .footer-link {
            .footer-links-list {
                margin-bottom: 0px;
                li {
                    margin-bottom: 20px;
                    a {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 21px;
                        color: $white-color;
                    }
                }
            }
        }
    }
    .footer-right-conent {
        .copyright-text {
            margin-bottom: 20px;
            small {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: $white-color;
            }
        }
        .footer-social-links {
            .footer-social-list {
                li {
                    margin-right: 0px;
                    a {                    
                        color: #fff;
                        margin-right: 20px;                    
                        display: block;
                        i {
                            font-size: 30px;
                        }
                    }
                }
            }
        }
    }
    
}
/*1. Footer Section CSS End*/