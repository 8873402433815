/*1. Hero Section CSS Start*/
.home-hero-section {
    width: 100%;
    height: 800px;        
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 11;
    &::before {
        content: '';
        position: absolute;
        background: linear-gradient(90.36deg, rgba(0, 0, 0, 0.5) 0.31%, rgba(0, 0, 0, 0) 67.95%);
        backdrop-filter: blur(7.5px);
        width: 70%;
        height: 100%;
        z-index: -1;
    }
    .hero-content-section {
        padding: 100px 0px;
        .hero-content-left {
            .hero-content-tagline {
                margin-bottom: 30px;
                h6 {
                    font-size: 20px;
                    font-weight: 400;
                    color: $white-color;
                    text-transform: uppercase;
                    letter-spacing: 0.2px;
                    margin-bottom: 0px;
                }                
            }
            .hero-content-title {
                margin-bottom: 30px;
                h1 {
                    font-size: 58px;
                    font-weight: 700;
                    line-height: 70px;
                    color: $white-color;
                    text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.4);
                    margin-bottom: 0px;
                }
            }
        }
        .hero-content-right {
            .chat-avtarbox {
                display: table;
                position: absolute;
                bottom: 0px;
                right: 125px;
                .chat-avtarbox-content {
                    display: table-cell;
                    vertical-align: bottom;
                    .avtarbox-body {
                        .chat-bubblebox {
                            .bubble-round-chat {
                                position: relative;
                                font-size: 16px;
                                width: 250px;
                                background: $white-color;
                                border-radius: 40px;
                                padding: 15px;
                                text-align: left;
                                color: $heading-color;
                                line-height: 19px;
                                margin: 0 -18px 20px auto;
                                display: block;
                                font-weight: 400;
                                font-style: italic;                                
                                &:before {
                                    content: "";
                                    width: 0;
                                    height: 0;
                                    position: absolute;
                                    border-left: 24px solid #fff;
                                    border-right: 12px solid transparent;
                                    border-top: 12px solid #fff;
                                    border-bottom: 20px solid transparent;   
                                    bottom: -18px;                                
                                }
                            }
                        }
                        .avtar-thumbnail {
                            img {
                                height: 430px;
                            }
                        }
                    }
                    &.chat-avtarbox-content-one {
                        .avtarbox-body {
                            .chat-bubblebox {
                                .bubble-round-chat {
                                    &:before {
                                        content: "";                                       
                                        left: 80px;                                        
                                    }
                                }
                            }
                        }           
                    }
                    &.chat-avtarbox-content-two {
                        .avtarbox-body {
                            .chat-bubblebox {
                                .bubble-round-chat {
                                    &:before {
                                        content: "";                                       
                                        right: 78px;                                        
                                        border-left: 12px solid transparent;
                                        border-top: 12px solid #fff;
                                        border-bottom: 40px solid transparent;
                                        border-right: 40px solid #fff;
                                    }
                                }
                            }
                        }           
                    }
                }
            }
        }
    }
}
/*1. Hero Section CSS End*/

/*2. Signmeup Section CSS Start*/
.signmeup-section {
    margin-top: -60px;
    position: relative;
    z-index: 11;
    margin-bottom: 80px;
    .signup-wrapper {       
        padding: 15px 15px 0 15px;
        .signme-up-content-left {
            border-right: 4px solid $grey-border;
            padding-right: 20px;
        }
        .signme-up-content-right {
            padding-left: 20px;
            p {
                font-size: 18px;
                line-height: 30px;
                color: $heading-color;
                font-weight: 400;
                margin-bottom: 20px;
            }
            span {
                display: block;
                font-style: italic;
                font-weight: 700;
                font-size: 18px;
                line-height: 30px;
                color: $primary-green-color;
            }
        }
    }
    &.gist-section {
        .signup-wrapper {
            padding: 45px;
            .signme-up-content-left {
                border-right: 0px;                
            }
            .signme-up-content-right {
                border-left: 4px solid $grey-border;
            }
            .signme-up-content-right {
                p {                    
                    // &:nth-of-type(3) {
                    //     margin-bottom: 0px;
                    // }
                }
            }
            .gist-vector {
                position: absolute;
                bottom: 0;
            }
        }
    }
}
/*2. Signmeup Section CSS End*/


/*3. Signmeup Section CSS Start*/
.services-section {
    margin-bottom: 100px;
    position: relative;
    z-index: 1;
    &:before {
        content: "";
        width: 100%;
        height: 100%;
        background-color: $white-color;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transform: skewy(-11deg);
        transform-origin: 34% 0;
        outline: 1px solid transparent;
        backface-visibility: hidden;
        z-index: -1;
    }
    .service-title-block {
        .section-heading-content {
            text-align: center;
            margin-bottom: 40px;
            .section-title {
                h3 {
                    line-height: 44px;
                }
            }
        }
    }
    .service-card-block {
        .card-col-align {
            margin-bottom: 50px;                    
            .card-box {
                background: #FFFFFF;
                border: 1px solid #0000001a;
                border-radius: 15px;
                margin-bottom: 30px;
                padding: 20px;
                height: 100%;
                .card-icon {
                    width: 100px;
                    height: 100px;
                    background: $white-color;
                    box-shadow: 0px 0px 10px #0000001a;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    margin: -60px auto 30px auto;
                    img {
                        width: 52px;
                    }
                }
                .card-title {
                    text-align: center;
                    margin-bottom: 30px;
                    h6 {
                        font-weight: 700;
                        font-size: 22px;
                        line-height: 27px;
                        color: $heading-color;
                        margin-bottom: 0;
                    }
                }
                .card-desc {
                    .card-desc-list {
                        list-style: square;
                        margin-bottom: 0;
                        li {
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 30px;
                            color: $heading-color;
                        }
                    }
                }
                &:hover {
                    background: $primary-green-color;
                    box-shadow: 0px 0px 25px #00000026;
                    .card-title {
                        h6  {
                            color: $white-color;
                        }
                    }
                    .card-desc {
                        .card-desc-list {
                            li {
                                color: $white-color;
                            }
                        }
                    }
                }
            }
        }
        
    }
    &.corevalue-section {
        .service-title-block {
            margin-bottom: 40px;
        }
        &:before {
            content: none;
        }
        .service-card-block {
            .card-col-align {
                .card-box {
                    .card-title {
                        margin-bottom: 0px;
                    }
                }
            }
        } 
    }
}
/*3. Signmeup Section CSS End*/

/*4. Mission Section CSS Start*/
.mission-section {
    padding:100px 0px;
    background-color: $white-color;
    .mission-title-block {
        margin-bottom: 50px;        
    }
    .targated-block {
        .target-left {
            padding: 74px;
            .section-heading-content {
                margin-bottom: 30px;
            }
            .target-desc-block {
                .target-points-list {
                    list-style: none;
                    counter-reset: item;
                    li {
                        font-weight: 400;
                        font-size: 22px;
                        line-height: 27px;
                        color: $heading-color;
                        margin-bottom: 20px;
                        counter-increment: item;
                        &:before {
                            margin-right: 15px;
                            content: counter(item);
                            background: $primary-green-light;
                            border-radius: 100%;
                            color: $primary-green-color;
                            width: 40px;
                            text-align: center;
                            display: inline-block;
                            font-size: 16px;
                            height: 40px;
                            padding: 7px;
                        }
                    }
                }
            }
        }
        .target-right {
            text-align: right;
            img {
                height: 524px;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
            }
        }
    }
}
/*4. Mission Section CSS End*/

/*5. Testimonial Section CSS Start*/
.testimonial-section {
    background-color: #e5f2f4;
    padding: 100px 0px;
    position: relative;
    z-index: 1;
    .testimonial-title-block {
        margin-bottom: 50px;
    }
    .testimonial-block {
        text-align: center;
        position: relative;
        .testimonial-slide {
            .quote-pic {
                margin-bottom: 30px;
                img {
                    width: 60px;
                    margin: 0 auto;
                }
            }
            .quote-description {
                font-size: 24px;
                line-height: 32px;
                font-weight: 400;
                color: $paragraph-color;
                margin-bottom: 30px;
            }                         
            .testimonial-profile-box {
                display: flex;
                justify-content: center;
                align-items: center;                
                .testimonial-profile-thumbnail {
                    margin-right: 10px;
                    img {
                        width: 60px;
                    }
                }
                .testimonial-profile-contnet {
                    .client-name {
                        font-weight: 700;
                        font-size: 20px;                            
                        text-align: left;
                        color: $black-color;
                        margin-bottom: 0px;
                    }
                    .client-post {
                        font-weight: 400;
                        font-size: 18px;                
                        text-align: left;
                        color: $black-color;
                    }
                }                
            }
        }
    }
    .testimonial-vector {
        position: absolute;
        bottom: -100px;
        right: 70px;
        z-index: -1;
        img {
            width: 230px;
        }
    }
    .testimonial-circle-wrapper {
        display: none;
        .testimonial-shape-image {
            position: absolute;
            z-index: -1;
            img {
                width: 50px;
                height: 50px;
            }
            &.testimonial-image-1 {
                bottom: 0;
                top: 60%;
                left: 10%;
            }
            &.testimonial-image-2 {
                left: 10%;
                top: 5%;
            }
            &.testimonial-image-3 {
                left: 20%;
                bottom: 19%;
            }
            &.testimonial-image-4 {
                left: 12%;
                top: 30%;
            }
            &.testimonial-image-5 {
                top: 10%;
                right: 10%;
            }            
    
        }
    }
}
/*5. Testimonial Section CSS End*/

/*6. Contact Section CSS Start*/
.contactus-section {
    margin-top: -50px;
    padding-bottom: 200px;
    position: relative;
    z-index: 1;
    .contact-form {
        padding: 40px 50px 15px 50px;
    }
}
/*6. Contact Section CSS End*/

/*7. Newslatter Section CSS Start*/
.news-latter-section {
    padding-bottom: 250px;
    background: #f2f8f9;
    padding-top: 100px;
    .section-heading-content {
        margin-bottom: 20px;
        .section-title {
            h3 {
                b{
                    font-weight: 700;
                    font-style: italic;
                }
            }
        }
    }
    .news-latter-block {
        .input-group {
            align-items: center;
            .form-control {
                background: #e5f2f4;
                border: 1px solid #FFFFFF;
                border-top-left-radius: 50px !important;
                border-bottom-left-radius: 50px !important;
                border-top-right-radius: 50px !important;
                border-bottom-right-radius: 50px !important;
                height: 55px;
                padding: 6px 20px;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: #00000080;
                &:hover,&:focus {
                    outline: 0;
                    box-shadow: none;
                }
            }
            .btn-search {
                padding: 11px 38px;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                background: #183442;
                border-radius: 40px;
                color: #fff;
                position: absolute;
                right: 10px;
                z-index: 11;
                border-top-left-radius: 50px !important;
                border-bottom-left-radius: 50px !important;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
            }
        }
    }
}
/*7. Newslatter Section CSS End*/