/*Timeline CSS Start*/
.process-flow-section {
	padding: 100px 0 200px 0;
    background-color: $white-color;
	position: relative;
	z-index: 1;
}


.workflow-circle-wrapper {
    .workflow-shape-image {
        position: absolute;
        z-index: -1;
        &.shape-image-1 {
            top: -12%;
			left: 0;
        }
        &.shape-image-2 {
            left: 10%;
    		top: 5%;
        }
        &.shape-image-3 {
            left: 17%;
    		bottom: -29%;
        }
        &.shape-image-4 {
            right: 0;
			top: 0;
        }
        &.shape-image-5 {
            bottom: 30%;
    		left: 50%;
        }
		&.shape-image-6 {
            left: 8%;
    		bottom: -6%;
        }
		&.shape-image-7,&.shape-image-8 {
            display: none;
        }

    }
}

.process-shapes-wrapper {
	.proces-shapes-image {
		position: absolute;
        z-index: -1;
        &.flow-image-1 {
            top: 40%;
    		left: 0;
        }
        &.flow-image-2 {
            right: 0;
    		top: 5%;
        }
        &.flow-image-3 {
			right: 0;
			bottom: 0;
        }
		&.flow-image-4,&.flow-image-5 {
			display: none;	
		}		
	}
}
.timeline {
	position: relative;
	padding: 4px 0 0 0;
	margin-top: 50px;
	list-style: none;
	max-width: 1000px;
	>li {
		&:nth-child(even) {
			position: relative;
			margin-bottom: 50px;
			height: 100px;
			right: 39px;
			.line {
				&:before {
					content: "";
                    position: absolute;
                    top: 80px;
                    bottom: 0;
                    left: 370px;
                    width: 25px;
                    height: 240px;
                    background-color: #72b0bc;
                    transform: rotate(44deg);
                    box-shadow: 0 0 5px #72b0bc;                    
				}
			}
		}
		&:nth-child(odd) {
			position: relative;
			margin-bottom: 50px;
			height: 100px;
			left: -200px;
			.line {
				&:before {
					content: "";
                    position: absolute;
                    top: 60px;
                    bottom: 0;
                    left: 586px;
                    width: 25px;
                    height: 240px;
                    background-color: #72b0bc;
                    transform: rotate(-44deg);
                    box-shadow: 0 0 5px #72b0bc;
				}
			}
		}
		&:nth-child(3) {
			.timeline-panel {
				margin-right: 73px;
			}
		}
		&:before {
			content: " ";
			display: table;
		}
		&:after {
			content: " ";
			display: table;
			clear: both;
			min-height: 170px;
		}
		.timeline-panel {
			position: relative;
			float: right;			
			padding: 0px;
			text-align: left;
			left: 0px;
			top: 8px;
			&:before {
				right: auto;
				left: -15px;
				border-right-width: 15px;
				border-left-width: 0;
			}
			&:after {
				right: auto;
				left: -14px;
				border-right-width: 14px;
				border-left-width: 0;
			}
		}
		.timeline-image {
			z-index: 100;
            position: absolute;
            left: 52%;
            border: 20px solid #72b0bc;
            border-radius: 100%;
            background-color: #ffffff;
            box-shadow: 0 0 5px #72b0bc;
            width: 160px;
            height: 160px;
            margin-left: -100px;
			h4 {
				margin-top: 12px;
				font-size: 10px;
				line-height: 14px;
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
        .timeline-image {
            img {
                max-width: 85px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 121px;
                margin: 0 auto;
                padding: 10px;
            }
        }
	}
	>li.timeline-inverted {
		>.timeline-panel {
			float: right;
			padding: 0 55px 20px 20px;
			left:0;
			text-align: left;
			&:before {
				right: auto;
				left: -15px;
				border-right-width: 15px;
				border-left-width: 0;
			}
			&:after {
				right: auto;
				left: -14px;
				border-right-width: 14px;
				border-left-width: 0;
			}
		}
	}
	.timeline-heading {
		h4 {
			margin-top: 22px;
			margin-bottom: 4px;
			padding: 0;			
			color: $heading-color;
			font-size: 26px;
			line-height: 32px;
			font-weight: 700;
		}		
	}
	.timeline-body {		
		.timeline-bullet-points {
			list-style: square;
			text-align: left;
			li {
				span {
					font-weight: 400;
					font-size: 14px;
					line-height: 21px;
				}	
			}
		}
	}
}
// @media (min-width: 992px) and (max-width: 1199px) {
// 	.timeline {
// 		>li {
// 			&:nth-child(even) {
// 				margin-bottom: 0px;
// 				min-height: 0px;
// 				right: 0px;
// 				.timeline-image {
// 					left: 0;
// 					margin-left: 0px;
// 				}
// 				.timeline-panel {
// 					width: 76%;
// 					padding: 0 0 20px 0px;
// 					text-align: left;
// 				}
// 			}
// 			&:nth-child(odd) {
// 				margin-bottom: 0px;
// 				min-height: 0px;
// 				left: 0px;
// 				.timeline-image {
// 					left: 690px;
// 					margin-left: 0px;
// 				}
// 				.timeline-panel {
// 					width: 70%;
// 					padding: 0 0 20px 0px;
// 					text-align: right;
// 				}
// 			}
// 			.line {
// 				display: none;
// 			}
// 		}
// 	}
// }
// @media (min-width: 768px) and (max-width: 991px) {
// 	.timeline {
// 		>li {
// 			&:nth-child(even) {
// 				margin-bottom: 0px;
// 				min-height: 0px;
// 				right: 0px;
// 				.timeline-image {
// 					left: 0;
// 					margin-left: 0px;
// 				}
// 				.timeline-panel {
// 					width: 70%;
// 					padding: 0 0 20px 0px;
// 					text-align: left;
// 				}
// 			}
// 			&:nth-child(odd) {
// 				margin-bottom: 0px;
// 				min-height: 0px;
// 				left: 0px;
// 				.timeline-image {
// 					left: 520px;
// 					margin-left: 0px;
// 				}
// 				.timeline-panel {
// 					width: 70%;
// 					padding: 0 0 20px 0px;
// 					text-align: right;
// 				}
// 			}
// 			.line {
// 				display: none;
// 			}
// 		}
// 	}
// }

@media only screen and (max-width: 1199px) {
	.timeline {
		max-width: 100%;
    	display: flex;
    	flex-flow: row wrap;
		padding: 10px;		
		>li {
			width: 50%;
			&:nth-child(even) {
				margin-bottom: 20px;
				min-height: initial;				
				position: initial;
				height: auto;
				.timeline-image {
					left: 0;
					margin-left: 0;
					width: 66px;
    				height: 66px;
					box-shadow: 0px 0px 15px #0000001a;
					border: 0px;
					img {
						max-width: 70px;
						height: 70px;
					}
				}
				.timeline-panel {
					width: 100%;
					padding: 0px;
					left: 0;
    				top: 0;				
				}
			}
			&:nth-child(odd) {
				margin-bottom: 20px;
				min-height: initial;				
				position: initial;
				height: auto;
				.timeline-image {
					left: 0;
					margin-left: 0;
					width: 66px;
    				height: 66px;
					box-shadow: 0 0 5px #000;
					border: 0px;
					img {
						max-width: 70px;
						height: 70px;
					}
				}
				.timeline-panel {
					width: 100%;
					padding: 0px;	
					position: initial;				
				}
			}
			&:nth-child(3) {
				.timeline-panel {
					margin-right: 0px;
				}
			}
			.timeline-image {
				position: static;
				width: 150px;
				height: 150px;
				margin-bottom: 0px;				 
			}
			.line {
				display: none;
			}
			&:after {
				content: none;
			}
		}
		.timeline-heading {					
			margin-top: -69px;
    		margin-left: 80px;
			h4 {
				font-size: 20px;
    			line-height: 24px;
			}		
			
		}
		.timeline-body {
			margin-top: 40px;
		}		
		
	}
	.process-shapes-wrapper {
		.proces-shapes-image {
			&.flow-image-1,&.flow-image-2 {
				display: none;	
			}	
			&.flow-image-3 {					
				text-align: center;
    			width: 100%;
				left: 0;
				right: 0;
			}	
			&.flow-image-4 {
				display: block;
				top: 40%;
				left: 0;
				transform: rotate(180deg);					
			}
			&.flow-image-5 {
				display: block;
				top: 20%;
				right: 0;
				transform: rotate(180deg);
			}
		}
	}	
	.plan-bg-hero-section {
		&.workflow-hero-section {
			background-color: #F2F8FA;		
			z-index: 11;
		}
	}
	.workflow-circle-wrapper {
		.workflow-shape-image {
			position: absolute;
			z-index: -1;
			&.shape-image-1,&.shape-image-3,&.shape-image-4,&.shape-image-5 {
				display: none;
			}					
			&.shape-image-2 {
				left: 48%;
    			top: -15%;
				z-index: 1;
			}
			&.shape-image-7 {
				display: block;
				z-index: 1;
				top: 0;
				right: 0;
				z-index: 1;
				img {
					width: 100px;
				}
			}
			&.shape-image-8 {
				display: block;
				z-index: 1;
				top: -20%;
				left: 0;
			}
	
		}
	}	
	.process-flow-section {
		padding: 50px 0 200px 0;
	}
}
@media only screen and (max-width: 767px) {
	.timeline {		
		flex-flow: column wrap;
		>li {
			width: 100%;			
		}		
	}
	
}
/*Timeline CSS End*/
